import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Divider } from '@material-ui/core';
import ResponsiveHeading from '../../../components/utils/responsive-heading';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <ResponsiveHeading mdxType="ResponsiveHeading">Getting here</ResponsiveHeading>
    <Divider mdxType="Divider" />
    <p>{`Because we're located centrally in Albany, getting here couldn't be easier. Just take the Corporate Woods exit (5A) off I-90, and we're the 3rd building on the LEFT. Computer Visions is on the 2nd floor. We've provided a map below to give you a better idea visually. Clicking the map will take you to Google maps where you can get even more specific directions:`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      