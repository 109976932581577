import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Divider } from '@material-ui/core';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`From North`}</h2>
    <p>{`Directions to our facility are: From Saratoga County and all points north, Take I-87 (Northway) heading south, stay on 87 until you reach exit 1-E (I-90E) merge onto I-90 heading east, follow for approx 5mi get off exit 5A (Corporate Woods), follow the exit ramp thru the light, we are building #16, the 3rd building on the left, Computer Visions is located on the 2nd floor.`}</p>
    <Divider mdxType="Divider" />
    <h2>{`From East`}</h2>
    <p>{`Directions to our facility are: Rensselaer County and all points east merge onto I-90 heading west to Albany, follow I-90 to exit 5A (Corporate Woods), follow the exit ramp thru the light, we are building #16, the 3rd building on the left, Computer Visions is located on the 2nd floor.`}</p>
    <Divider mdxType="Divider" />
    <h2>{`From West`}</h2>
    <p>{`Directions to our facility are: From Schenectady and Western NY merge onto I-90 heading east, follow I-90 thru exit 24 tolls, remain on I-90 to exit 5A (Corporate Woods), follow the exit ramp thru the light, we are building #16, the 3rd building on the left, Computer Visions is located on the 2nd floor.`}</p>
    <Divider mdxType="Divider" />
    <h2>{`From South`}</h2>
    <p>{`Directions to our facility are: From Poughkeepsie and all points south merge onto I-87 heading north, follow I-87 to exit 24 (Albany), merge onto I-90 heading east to exit 5A (Corporate Woods), follow the exit ramp thru the light, we are building #16, the 3rd building on the left, Computer Visions is located on the 2nd floor.`}</p>
    <Divider mdxType="Divider" />
    <p>{`If you're having trouble figuring out how to get here, why not give us a `}<a parentName="p" {...{
        "href": "tel:+1-518-437-1600"
      }}>{`call`}</a>{`? We'd be happy to explain the best route for you.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      